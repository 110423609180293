import React from "react";

function Footer() {
  return (
    <div className="bg-[#630A10] flex flex-col text-white justify-center items-center py-2">
      <p>v1.1.0</p>
      <p>2023 &copy; All rights reserved.</p>
      <p>Department of IT | NOLIMIT</p>
    </div>
  );
}

export default Footer;
