import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Collapsible from "react-collapsible";
import { FaMinus, FaPlus } from "react-icons/fa6";
import FilterComponent from "../Components/Filter";

const Menu = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/menu.json");
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const groupByCategory = (data) => {
    return data.reduce((groups, item) => {
      const category = item.CATEGORY;
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(item);
      return groups;
    }, {});
  };

  const groupedData = groupByCategory(data);

  return (
    <div>
      <Navbar />
      {/* <FilterComponent items={item} /> */}
      <div className=" px- py-10 bg-slate-900">
        <div className="flex justify-center text-center text-[#FB8B24]">
          <h1 className="text-3xl font-semibold">Our Menu</h1>
        </div>

        <div className="flex flex-col justify-between md:px-28 px-8 text-white">
          {Object.entries(groupedData).map(([category, items]) => (
            <div
              key={category}
              className="flex justify-between w-full border-b bg-border border-slate-600"
            >
              <Collapsible
                trigger={category}
                className="w-full text-lg text-[#FB8B24] py-4  px-6 uppercase"
                openedClassName=" w-full text-lg text-[#FB8B24] pt-4 border-b bg-border border-slate-600 px-6 uppercase"
              >
                <div>
                  <h3 className="pl-4 pt-5 pb-2 text-2xl font-semibold text-white">
                    {category}
                  </h3>
                  <ul className="pl-4">
                    {items.map((item, index) => (
                      <li className="py-2 " key={index}>
                        <p>{item.ITEM}</p>
                        <p className="py-1 font-semibold text-white">
                          Rs: {item.SP}
                        </p>
                        <hr className="bg-border border-slate-600 opacity-70" />
                      </li>
                    ))}
                  </ul>
                </div>
              </Collapsible>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Menu;
