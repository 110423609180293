import { Link } from "react-scroll";
import Card from "../Components/Card";
import React, { useEffect, useState } from "react";
import ArrowR from "../Assets/icons8-right-arrow.gif";

const Category = () => {
  const [jsonData, setJsonData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/popcat.json");
        const jsonData = await response.json();
        setJsonData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bg-[#FCF0C8]">
      <h1 className="text-center pt-4 font-semibold text-2xl uppercase font-poppins text-[#682C0E]">
        Signature Dishes
      </h1>
      <div className="px-24 py-8 flex justify-center gap-6 overflow-x-auto columns-4 mx-10">
        {jsonData.map((item) => (
          <div className="ml-12 w-[250px] h-[300px]">
            <Link to={"/menu"}>
              <Card
                key={item.category}
                title={item.category}
                description={item.description}
                imageUrl={item.imageUrl}
              />
            </Link>
          </div>
        ))}
      </div>
      <div className="flex justify-center py-2 opacity-60">
        <button className="flex items-center gap-2 font-semibold">
          Slide <img src={ArrowR} className="w-6 "></img>
        </button>
      </div>
    </div>
  );
};

export default Category;
