// FilterComponent.js
import React, { useState } from "react";

const FilterComponent = ({ onFilterChange, items }) => {
  const handleFilterChange = (e) => {
    // Retrieve the filter value and send it to the parent component
    const filterValue = e.target.value;
    onFilterChange(filterValue);
  };
  const [filteredItems, setFilteredItems] = useState(items);

  const handleFilterValue = (filterValue) => {
    // Apply the filter based on the filterValue
    const filtered = items.filter((item) =>
      item.toLowerCase().includes(filterValue.toLowerCase())
    );

    setFilteredItems(filtered);
  };

  return (
    <div>
      <input type="text" onChange={handleFilterChange} />
      <FilterComponent onFilterChange={handleFilterValue} />
      <ul>
        {filteredItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default FilterComponent;
