import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Menu from "../Pages/Menu";
import Contact from "../Pages/Contact";
import Category from "../Pages/Category";
import GoToTopButton from "./TopBtn";

function Layout() {
  return (
    <div className="min-w-[100%] m-0">
      <Navbar />
      <div>
        <Home />
        <Category />
        <About />

        <Contact />
        <Footer />
        <Outlet />
      </div>
      <GoToTopButton />
    </div>
  );
}

export default Layout;
