import React from "react";
import BG from "../Assets/bg.webp";
import Logo from "../Assets/logo.png";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import { FaAnglesDown } from "react-icons/fa6";
function Home() {
  return (
    <div
      id="home"
      className="flex bg-[url('./Assets/bg.webp')] bg-cover bg-no-repeat bg-center md:h-screen lg:max-h-screen lg:pt-20 md:w-full min-w-full scroll-smooth"
    >
      <div className="bg-gradient-to-t from-slate-950 w-full px-16 text-center md:text-left">
        <div className="md:w-1/2 w-full text-white pt-20">
          <h3 className="text-xl font-medium uppercase font-cursive py-2">
            welcome to
          </h3>
          <h1 className="md:text-6xl text-4xl font-bold uppercase font-cursive py-4 text-[#FB8B24] ">
            paadum meen
          </h1>
          <p className="text-xl font-cursive font-normal uppercase">
            restaurant
          </p>
          <p className="py-5 text-lg md:tracking-wide">
            Paadum Meen restaurant is an exquisite Eastern cuisine fine-dining
            establishment located in Colombo 04. Established in 2022, this
            culinary gem offers a delectable journey through the rich flavors of
            Eastern cuisine. With a focus on delivering an unparalleled dining
            experience, Paadum meen Restaurant is renowned for its delicious and
            meticulously crafted dishes that showcase the authenticity and
            diversity of Eastern culinary traditions...
          </p>
          <Link to="/menu">
            <button className="md:my-4 my-8 py-2 px-6 bg-[#E36414] rounded-full hover:bg-opacity-60 duration-300">
              Our Menu
            </button>
          </Link>
        </div>
        <div className="md:flex justify-center md:pt-20 hidden ">
          <FaAnglesDown className="text-white animate-bounce text-2xl opacity-70" />
        </div>
      </div>
    </div>
  );
}

export default Home;
