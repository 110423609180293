import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Layout from "./Components/Layout";
import Menu from "./Pages/Menu";

import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Blog from "./Pages/Blog";

function App() {
  return (
    <div className="scroll-smooth w-full m-0">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/home" index Component={<Home />} />
          </Route>
          <Route path="/menu" element={<Menu />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
