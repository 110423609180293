import React, { useState } from "react";
import Logo from "../Assets/pm-logo-w.png";
import { HiMiniBars3BottomRight } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="sticky top-0 z-50 w-full">
      <nav className="md:flex flex-wrap justify-between px-4 md:px-8 lg:px-32 py-1 items-center bg-[#630A10] bg-cover shadow-lg transition ease-in-out">
        <div>
          <a href="/">
            <img src={Logo} alt="logo" className="w-52 py-2" />
          </a>
        </div>

        <div className="md:flex">
          <div
            className="md:hidden cursor-pointer flex flex-nowrap justify-end top-3"
            onClick={toggleMenu}
          >
            {isOpen ? (
              <IoClose className=" text-white text-2xl mt-[-42px] mr-5" />
            ) : (
              <HiMiniBars3BottomRight className="text-white text-2xl  mt-[-42px] mr-5" />
            )}
          </div>
          <ul
            className={`${
              isOpen
                ? "flex flex-col justify-start py-3 transition ease-in-out"
                : "hidden"
            } md:flex md:flex-row md:justify-center items-center md:gap-6 text-white w-full md:w-auto transition ease-in-out`}
          >
            <li className="py-2">
              <a
                className="px-3 active:bg-[#FB8B24] hover:bg-[#FB8B24] py-2 text-center rounded cursor-pointer duration-300"
                href="/"
              >
                Home
              </a>
            </li>
            <li className="py-2">
              <a
                className="px-3 active:bg-[#FB8B24] hover:bg-[#FB8B24] py-2 text-center rounded cursor-pointer duration-300"
                href="/#about"
              >
                About
              </a>
            </li>
            <li className="py-2">
              <Link
                to="/menu"
                className="px-3 active:bg-[#FB8B24] hover:bg-[#FB8B24] py-2 text-center rounded cursor-pointer duration-300"
              >
                Menu
              </Link>
            </li>
            <li className="py-2">
              <a
                href="/#contact"
                className="px-3 active:bg-[#FB8B24] hover:bg-[#FB8B24] py-2 text-center rounded cursor-pointer duration-300"
              >
                Contact Us
              </a>
            </li>
            {/* <li className="py-2">
              <Link
                to="/blog"
                className="px-3 active:bg-[#FB8B24] hover:bg-[#FB8B24] py-2 text-center rounded cursor-pointer duration-300"
              >
                Blog
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
