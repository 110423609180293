import React from "react";
import Logo from "../Assets/pm-logo-w.png";

function Contact() {
  return (
    <div id="contact" className="bg-[#911F27] md:px-16 px-8 py-5">
      <h2 className="text-center py-4 text-2xl text-white">Contact Us</h2>
      <div className="md:flex block justify-between">
        <div className="text-white md:w-1/2 w-full justify-center">
          <img src={Logo} alt="Logo" className="w-36 mx-auto md:mx-0" />
          <h1 className="text-xl font-semibold uppercase tracking-wider py-2 md:text-left text-center">
            paadum meen
          </h1>
          <p className="md:text-left text-center">
            Paadummeen Restaurant in Colombo-4, founded in 2021, offers an
            exquisite Eastern culinary experience. This fine-dining gem is
            celebrated for its meticulously crafted dishes, showcasing the rich
            flavors of Eastern cuisine and delivering an unforgettable dining
            journey in the heart of Colombo.
          </p>
          <div className="flex gap-10 py-4">
            <div>
              <p>Location :</p>
              <p>Contact No :</p>
              <p>Email :</p>
            </div>
            <div>
              <p>08 A Frankfort Pl, Colombo 00400</p>
              <p>0112590366</p>
              <p>info@paadummeen.lk</p>
            </div>
          </div>
        </div>
        <div className="flex justify-start">
          <iframe
            className="border-none md:w-[400px] w-[250px] md:h-[400px] h-[250px]"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4023.3069046962114!2d79.85496125131702!3d6.8849950306234335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25bcf15e9b7dd%3A0xcd486f55e56a46df!2sPaadum%20Meen!5e0!3m2!1sen!2slk!4v1702379079248!5m2!1sen!2slk"
            title="Google Maps"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
