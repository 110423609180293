import React from "react";

const Card = ({ title, description, imageUrl }) => {
  return (
    <div className="bg-[#FACE7F] w-[250px] h-[300px] rounded-xl shadow-xl p-4 text-white lg:ml-[30px] md:ml-[100px] md:mr-[450px] ml-[600px] hover:scale-110 duration-300 cursor-pointer">
      <img
        src={imageUrl}
        alt={title}
        className="w-full h-44 object-cover rounded-lg shadow-lg"
      />
      <h2 className="pt-2 font-lato font-semibold text-[#630A10] uppercase">
        {title}
      </h2>
      <p className="text-black font-poppins font-light text-opacity-80">
        {description}
      </p>
    </div>
  );
};

export default Card;
