import React from "react";
import AbtBg from "../Assets/abt-bg.webp";

function About() {
  return (
    <div
      id="about"
      className="flex flex-col justify-center text-center md:py-10 md:px-24 px-4 bg-[#FACE7F] text-white "
    >
      {/* <img
        src={AbtBg}
        className="bg-cover  mix-blend-multiply opacity-5 absolute bg-repeat-y w-full"
      /> */}
      <div>
        <h1 className="py-4 text-2xl uppercase text-[#630A10]">About Us</h1>
        <p className="px-10 tracking-wide text-justify text-[#911F27]">
          Paadummeen Restaurant is an exquisite Eastern cuisine fine-dining
          establishment located in Colombo-4. Established in 2022, this culinary
          gem offers a delectable journey through the rich flavors of Eastern
          cuisine. With a focus on delivering an unparalleled dining experience,
          Paadummeen Restaurant is renowned for its delicious and meticulously
          crafted dishes that showcase the authenticity and diversity of Eastern
          culinary traditions. From tantalizing spices to savory delights,
          patrons can savor a symphony of flavors that reflect the restaurant's
          commitment to culinary excellence. Whether indulging in traditional
          favorites or exploring innovative creations, Paadummeen Restaurant
          promises a memorable dining experience that captures the essence of
          Eastern cuisine in the heart of Colombo.
        </p>
      </div>
      <div className="py-4">
        <button className="my-4 py-2 bg-[#E36414] px-6 rounded-full">
          More.
        </button>
      </div>
    </div>
  );
}

export default About;
